<template>
  <b-card class="mb-2">
    <b-card-text class="d-flex flex-column flex-lg-row justify-space-between">
      <div class="flex-fill" v-if="!selected || selected.id !== (value && value.id)">
        <slot name="placeholder"></slot>
      </div>
      <div class="flex-fill mr-2 minw-0" v-if="(selected && selected.id) === (value && value.id)">
        <slot name="selected" :selected="selected">
          <p class="h3 font-weight-bold text-nowrap text-primary text-truncate">{{ selected && selected.company_name }}</p>
          <p class="mb-0 text-muted">{{ selected && selected.address && selected.address.address_snippet }}</p>
        </slot>
      </div>
      <div class="align-self-lg-center d-flex flex-column mt-2 mt-lg-0">
        <b-button block class="text-nowrap" variant="secondary" v-b-modal.assign-customer>Assign Customer</b-button>
      </div>
    </b-card-text>

    <b-modal id="assign-customer" size="lg" cancel-title="Close" ok-title="Assign"
      title="Assign Customer" ok-variant="secondary" cancel-variant="dark" :ok-disabled="!selected"
      @ok="$emit('input', selected)" body-class="p-0">
      <div class="p-3 pb-0">
        <b-form-group class="mb-0" label="Customer Search" label-for="customer-search" label-sr-only>
          <b-form-input debounce="500" @update="fetch({ query })" id="customer-search" v-model="query" v-b-popover.hover.top="'Search for customers that have already been added to the system.'" />
        </b-form-group>
      </div>
      <div class="text-center mt-2 mb-4" v-if="searching">
        <b-spinner variant="secondary" />
      </div>
      <b-list-group class="border-top" flush v-if="!searching && results && results.length">
        <b-list-group-item button @click.prevent="selected = customer" :class="{'bg-selected': customer.id === (selected && selected.id)}" :key="customer.id" v-for="customer in results">
          <slot name="result" :customer="customer">
            <div class="d-flex flex-column flex-fill">
              <p class="h5 flex-fill mb-0 text-primary">{{ customer.company_name }}</p>
              <p class="text-muted mb-0"><small>{{ customer.company_number }} - {{ customer.address && customer.address.address_snippet }}</small></p>
            </div>
          </slot>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    type: String,
    value: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      selected: null,
      query: ''
    }
  },
  mounted () {
    if (this.value) {
      this.selected = this.value
    }
  },
  computed: {
    results () {
      return this.$store.getters[`${this.type}-customers/data`]
    },
    searching () {
      return this.$store.getters[`${this.type}-customers/isLoading`]
    }
  },
  methods: {
    ...mapActions({
      fetch (dispatch, payload) {
        return dispatch(`${this.type}-customers/fetchManyCustomers`, payload)
      }
    })
  },
  watch: {
    value (value) {
      if (value) {
        this.selected = { ...this.selected, ...value }
      }
    }
  }
}
</script>
